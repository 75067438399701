import React from 'react';

export default function Footer() {
  return (
    <footer>
      <div className="container">
        <ul className="my-2 list-inline">
          <li className="list-inline-item">
            <a href="/">Home</a>
          </li>
          <li className="list-inline-item">
            <a href="/privacy-policy">Privacy</a>
          </li>
          <li className="list-inline-item">
            <a href="/tos-agreement">Terms</a>
          </li>
        </ul>
        <div className="my-2">
          <p>Google Play and the Google Play logo are trademarks of Google LLC.</p>
          <p>Apple® and The Apple logo® are trademarks of Apple Inc.</p>
        </div>
        <p>&copy; Plebiant 2019-2020. All Rights Reserved.</p>
      </div>
    </footer>
  );
}
