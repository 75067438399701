import React from 'react';
import { Link } from 'gatsby';

import config from '../../config';
import classnames from 'classnames';
import gplay from '../assets/images/google-play-badge.png';
import astore from '../assets/images/appstore-badge.png';


const AppIcon = (props) => {

    const { title, subtitle, size, icon, link = {}} = props;
    const colSize = 'col-lg-' + size;
    const iconSize = 'app-icon-' + size;
    return (
        <div className={[colSize, 'my-5 text-center'].join(' ')}>
            <div className={["app-icon app-icon-rounded", iconSize].join(' ')}>
                {link.page 
                    ? <Link to={link.page}><img className="" src={icon} /></Link>
                    : <img className="" src={icon} />}
            </div>
            <div className="app-info m-2">
                <h3 className="text-light">{title}</h3>
                <div style={{display: 'flex', height: '84px', alignItems: 'center', justifyContent: 'center'}}>
                    <p className="subtitle text-muted">{subtitle}</p>
                </div>
                {link.ios && <a className="badge-link" href={link.ios}>
                    <img style={{width: 200, margin: 20}} src={astore} alt="" />
                </a>}
                {link.android && <a className="badge-link" href={link.android}>
                    <img style={{width: 128, margin: 12}}  src={gplay} alt="" />
                </a>}
            </div>
        </div>
    );
}

export default AppIcon;
